<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <h1 class="font-weight-bold">会社概要</h1>
        <table>
          <tbody>
            <tr>
              <th>会社名</th>
              <td>有限会社 徳永工務店</td>
            </tr>
            <tr>
              <th>代表者名</th>
              <td>徳永&nbsp;良二（トクナガ&nbsp;リョウジ）</td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td><a href="tel:0955526733">0955-52-6733</a></td>
            </tr>
            <tr>
              <th>資本金</th>
              <td>500万円</td>
            </tr>
            <tr>
              <th>所在地</th>
              <td>〒847-1442<br />佐賀県東松浦郡玄海町値賀川内１２１５－１</td>
            </tr>
            <tr>
              <th>建設業許可証</th>
              <td>
                <li>一般建設業許可 佐賀県知事許可 第007350号 土木一式工事</li>
                <li>一般建設業許可 佐賀県知事許可 第007350号 建築一式工事</li>
                <li>
                  一般建設業許可 佐賀県知事許可 第007350号
                  とび・土工・コンクリート工事
                </li>
                <li>一般建設業許可 佐賀県知事許可 第007350号 石工事</li>
                <li>一般建設業許可 佐賀県知事許可 第007350号 管工事</li>
                <li>一般建設業許可 佐賀県知事許可 第007350号 鋼構造物工事</li>
                <li>一般建設業許可 佐賀県知事許可 第007350号 舗装工事</li>
                <li>一般建設業許可 佐賀県知事許可 第007350号 しゅんせつ工事</li>
                <li>一般建設業許可 佐賀県知事許可 第007350号 水道施設工事</li>
                <li>一般建設業許可 佐賀県知事許可 第007350号 解体工事</li>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Company",
  mounted() {
    document.title = "徳永工務店 | 会社概要";
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", "徳永工務店の会社概要についてです。");
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse; /* セルの線を重ねる */
  margin: auto;
  text-align: left;
}
th {
  background-color: darkorange; /* 背景色指定 */
  color: #fff; /* 文字色指定 */
  font-weight: normal; /* 文字の太さ指定 */
  position: relative; /* 位置指定 */
  width: 30%;
  border-bottom: solid 1px #fff;
  font-weight: bold;
}
td {
  width: 70%;
  border-bottom: solid 1px darkorange;
}
a[href*="tel:"] {
  color: darkgreen;
  font-weight: bold;
}
@media (min-width: 751px) {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}
</style>
